// 图片上传弹窗
// sle
<template>
  <a-upload
    v-model:file-list="fileList"
    name="Image"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :action="apiUrl + action"
    :before-upload="beforeUpload"
    @change="handleChange"
    :data="data"
    :headers="head"
  >
    <img
      v-if="!imageUrl && image"
      :src="image"
      alt="avatar"
      :style="{ maxHeight: maxHeight + 'px', maxWidth: maxWidth + 'px' }"
    />
    <img
      v-if="imageUrl"
      :src="imageUrl"
      alt="avatar"
      :style="{ maxHeight: maxHeight + 'px', maxWidth: maxWidth + 'px' }"
    />
    <div v-if="!imageUrl && !image">
      <loading-outlined v-if="loading"></loading-outlined>
      <plus-outlined v-else></plus-outlined>
      <div class="ant-upload-text">上传</div>
    </div>
  </a-upload>
</template>
<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
import { getEnvVars } from '@/api/config'
const { apiUrl } = getEnvVars()

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default defineComponent({
  emits: ['callBack'],
  props: {
    maxHeight: {
      type: Number,
      default: 128,
    }, // 最大高度
    maxWidth: {
      type: Number,
      default: 128,
    }, // 最大宽度
    action: {
      type: String,
    }, // 上传接口
    image: {
      type: String,
    }, // 当前图片
    data: {
      true: Object,
    }, // 上传携带的参数
  },
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  computed: {
    returnImagaUrl () {
      return {
        image: this.imageUrl,
      }
    },
  },
  data () {
    return {
      apiUrl: apiUrl,
      fileList: [],
      loading: false,
      imageUrl: this.image,
      head: null,
    }
  },
  mounted () {
    const { token, tenant } = this.$store.state.account
    const head = {
      authorization: `${token.token_type} ${token.access_token}`,
      __tenant: tenant.tenantId,
    }
    this.head = head
  },
  methods: {
    handleChange (info) {
      switch (info.file.status) {
        case 'uploading':
          this.loading = true
          break
        case 'done':
          getBase64(info.file.originFileObj, base64Url => {
            // this.imageUrl = info.file.response.url // 发布前替换
            this.imageUrl = base64Url
            this.$emit('callBack', info.file.response)
            this.loading = false
          })
          break
        case 'error':
          this.loading = false
          this.$message.error('upload error')
          // console.log(info)
          break
      }
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isJpgOrPng) {
        this.$message.error('请选择图片类型的文件上传')
      }

      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        this.$message.error('图片大小不可超过2MB!')
      }

      return isJpgOrPng && isLt2M
    },
    clearImage () {
      // const form = new FormData()
      // form.append('Image',file)

      this.imageUrl = ''
    },
  },
})
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
  position: relative;
  padding: 2px;
  /* overflow: hidden; */
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload-select-picture-card img {
  width: 100%;
  max-height: 124px !important;
  max-width: 124px !important;
  object-fit: cover;
  height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
