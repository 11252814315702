// 企业信息
// sle
<template>
  <a-layout class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <page-head title="企业信息" />
    <a-spin :spinning="spinning">
      <div class="body flex-column">
        <span class="rows flex-row justify-between">
          <div class="flex-row" style="width: 60%">
            <div class="name" title="企业">企业名称:</div>
            <div class="value" v-if="!enterpriseName.edit">
              {{ obj.enterpriseName }}
            </div>
            <a-input
              class="input"
              v-model:value="enterpriseName.value"
              placeholder="企业名称"
              v-if="enterpriseName.edit"
              :maxlength="30"
            />
          </div>
          <div class="iconButton">
            <EditOutlined
              class="editOutlined"
              @click="editClick('enterpriseName')"
              v-if="!enterpriseName.edit && disabled"
            />
            <EditOutlined class="isHide" v-if="!disabled" :title="title" />
            <CheckOutlined
              class="affirm"
              @click="affirmClick('enterpriseName')"
              v-if="enterpriseName.edit"
            />
            <CloseOutlined
              @click="cancelClick('enterpriseName')"
              v-if="enterpriseName.edit"
            />
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex-row">
            <div class="name">企业logo:</div>
            <div class="value">
              <headUpload
                :disabled="!disabled"
                :title="!disabled == true ? title : ''"
                class="logo"
                :image="obj.enterpriseLogo"
                :action="action"
                :data="logoData"
                v-on:callBack="imageUpload"
              ></headUpload>
              <span class="tips"
                >建议尺寸800*800px；支持jpg/png格式；内存在3m以内；</span
              >
            </div>
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex-row">
            <div class="name">企业编号:</div>
            <div class="value">{{ obj.enterpriseCode }}</div>
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex-row">
            <div class="name">企业Key:</div>
            <div class="value">{{ obj.enterpriseKey }}</div>
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex-row">
            <div class="name">企业认证:</div>
            <div class="value">
              {{ obj.enterpriseProofName }}
            </div>
          </div>
          <div v-if="obj.enterpriseProof === 0 || obj.enterpriseProof === 2">
            <a-button
              @click="proofClick"
              :disabled="!disabled"
              :title="!disabled == true ? title : ''"
            >
              {{ obj.enterpriseProof === 0 ? "立即认证" : "重新认证" }}
            </a-button>
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex-row" style="width: 60%">
            <div class="name">地址:</div>
            <div class="value" v-if="!register.edit">{{ locationAddress }}</div>
            <a-cascader
              class="select"
              v-if="register.edit"
              :options="CityInfo"
              placeholder="请选择"
              size="large"
              v-model:value="register.value"
              @change="onChange"
            />
          </div>
          <div class="iconButton">
            <EditOutlined
              @click="editClick('location')"
              v-if="!register.edit && disabled"
            />
            <EditOutlined :title="title" class="hide" v-if="!disabled" />
            <CheckOutlined
              class="affirm"
              @click="affirmClick('location')"
              v-if="register.edit"
            />
            <CloseOutlined
              @click="cancelClick('location')"
              v-if="register.edit"
            />
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex" style="width: 60%">
            <div class="name">详细地址:</div>
            <div class="value" v-if="!detailAddress.edit">
              {{ obj.detailAddress }}
            </div>
            <a-input
              class="input"
              v-model:value="detailAddress.value"
              placeholder="所在地"
              v-if="detailAddress.edit"
              :maxlength="30"
            />
          </div>
          <div class="iconButton">
            <EditOutlined
              @click="editClick('detailAddress')"
              v-if="!detailAddress.edit && disabled"
            />
            <EditOutlined :title="title" class="hide" v-if="!disabled" />
            <CheckOutlined
              class="affirm"
              @click="affirmClick('detailAddress')"
              v-if="detailAddress.edit"
            />
            <CloseOutlined
              @click="cancelClick('detailAddress')"
              v-if="detailAddress.edit"
            />
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex" style="width: 60%; height: 100%">
            <div class="name">所在行业:</div>
            <div class="value" v-if="!industryName.edit">
              {{ obj.industryName }}
            </div>
            <a-select
              class="select"
              v-model:value="industryName.value"
              placeholder="所在行业"
              v-if="industryName.edit"
            >
              <a-select-option
                v-for="r in industryList"
                :key="r.linkKey"
                :value="r.linkKey"
              >
                {{ r.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="iconButton">
            <EditOutlined
              @click="editClick('industryName')"
              v-if="!industryName.edit && disabled"
            />
            <EditOutlined :title="title" class="hide" v-if="!disabled" />
            <CheckOutlined
              class="affirm"
              @click="affirmClick('industryName')"
              v-if="industryName.edit"
            />
            <CloseOutlined
              @click="cancelClick('industryName')"
              v-if="industryName.edit"
            />
          </div>
        </span>
        <span class="rows flex-row justify-between">
          <div class="flex-row align-center" style="height: 100%">
            <a-button v-if="disabledCreator" type="primary" @click="dissolution"
              >解散企业</a-button
            >
          </div>
        </span>
      </div>
    </a-spin>
    <!-- 上传营业执照弹窗 -->
    <a-modal
      v-model:visible="proofView.visible"
      title="上传营业执照"
      ok-text="确认"
      :width="800"
      :maskClosable="false"
      cancel-text="取消"
      @ok="proofConfirm"
      :confirmLoading="confirmLoading"
      @cancel="proofCancel"
    >
      <a-spin :spinning="proofView.spinning">
        <headUpload
          ref="proof"
          class="uploadLogo"
          :action="action"
          :data="proofView.data"
          :maxHeight="500"
          :maxWidth="750"
          v-on:callBack="proofUpload"
        ></headUpload>
      </a-spin>
    </a-modal>
    <a-modal
      v-model:visible="visibleDismiss"
      title="提示"
      ok-text="确认"
      :maskClosable="false"
      cancel-text="取消"
      @ok="dismissConfirm"
      :confirmLoading="confirmLoading"
      @cancel="dismissCancel"
    >
      <a-spin :spinning="spinning">
        <span style="display: inline-block; padding: 10px 0"
          >是否确定解散当前企业</span
        >
      </a-spin>
    </a-modal>
    <!-- <a-modal
      v-model:visible="visibleOut"
      :title="title"
      ok-text="确认"
      :maskClosable="false"
      cancel-text="取消"
      footer
      :closable="false"
    >
      <a-spin :spinning="spinning">
        <div class="refresh">您已退出当前企业,需重新登录</div>
        <div class="handle_logout">
          <a-button type="primary" @click="okLogout"> 确定 </a-button>
        </div>
      </a-spin>
    </a-modal> -->
  </a-layout>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons-vue'
import headUpload from '@/components/headUpload.vue'
import { isEmpty, enterpriseJurisdiction } from '@/assets/common.js'
import axios from 'axios'
import { getTenantList } from '@/api/IdentityAPI'

export default defineComponent({
  components: {
    'page-head': PageHead,
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    headUpload,
  },
  data () {
    return {
      visibleOut: false,
      visibleDismiss: false,
      disabled: false,
      title: '暂无权限',
      logoData: {
        companyId: null,
        type: 'logo',
      }, // logo上传时的data
      proofView: {
        spinning: false, // 是否加载
        visible: false, // 是否显示
        froofImageUrl: '', // 认证文件网络路径
        froofImageFath: '', // 认证文件物理路径
        data: {
          companyId: null,
          type: 'proof',
        }, // 认证文件上传时的data
      }, // 上传认证文件View
      action: '/UploadCompanyImages', // 上传路径
      enterpriseName: {
        edit: false,
        value: '',
      }, // 名称
      detailAddress: {
        edit: false,
        value: '',
      }, // 所在地
      register: {
        city: '',
        erae: '',
        minerae: '',
        value: [],
        edit: false,
      },
      CityInfo: [], // 地区数据
      industryName: {
        edit: false,
        value: '',
      }, // 行业
      industryList: [], // 行业列表
      spinning: false, // 是否在加载
      obj: {}, // 企业信息
      minHeight: 0, // 最小高度
      address: [],
      locationAddress: '',
      locationAddressValue: '',
      locationIndex: '',
      confirmLoading: false,
      tenantList: {},
      disabledCreator: false,
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getCompanyData()
    this.tenantList = JSON.parse(localStorage.getItem('tenantList'))
    this.tenantList.tenantList.forEach(element => {
      if (element.isLookUp === true) {
        this.disabledCreator = element.isCreator
      }
    })
  },
  mounted () {
    this.getJurisdiction()
    axios.get('/api/city.json').then(data => {
      this.CityInfo = data.data
    })
  },
  // 方法
  methods: {
    // 解散企业
    dissolution () {
      this.visibleDismiss = true
    },
    dismissConfirm () {
      this.spinning = true
      this.confirmLoading = true
      api
        .post('/api/app/ent-transfer/ent-dismiss', {
          id: this.obj.tenantId,
        })
        .then(({ data }) => {
          this.spinning = false
          this.confirmLoading = false
          // this.visibleOut = true
          // window.localStorage.clear()
          // // 清除cookie
          // window.document.cookie = 'userName' + '=' + '' + ';path=/;expires='
          // window.document.cookie = 'userPwd' + '=' + '' + ';path=/;expires='
          // history.pushState(null, null, document.URL)
          // window.addEventListener(
          //   'popstate',
          //   function (e) {
          //     history.pushState(null, null, document.URL)
          //   },
          //   false,
          // )
        })
        .catch(err => {
          // this.$message.error('操作失败')
          console.log(err)
          this.spinning = false
          this.confirmLoading = false
        })
    },
    // okLogout () {
    //   this.spinning = true
    //   window.location.href = '/account/login'
    // },
    dismissCancel () {
      this.visibleDismiss = false
    },
    // 获取权限
    getJurisdiction () {
      this.disabled = enterpriseJurisdiction()
    },
    // 选择地址
    onChange (value, selectedOptions) {
      this.address = []
      // this.locationIndex = (value.toString()).replace(/,/g, '-')
      this.locationIndex = value.toString()
      this.obj.locationIndex = this.locationIndex
      selectedOptions.forEach(element => {
        this.address.push(element.label)
        this.locationAddressValue = this.address.toString().replace(/,/g, '')
      })

      this.register.city = this.register.value[0]
      this.register.erae = this.register.value[1]
      this.register.minerae = this.register.value[2]
    },
    // 认证按钮，弹出认证上传页面
    proofClick () {
      this.proofView.visible = true
      this.proofView.image = ''
    },
    // 取消认证，关闭认证页面
    proofCancel () {
      this.proofView.visible = false
      this.proofView.image = ''
      this.$refs.proof.clearImage()
    },
    // 确认认证，上传认证按钮
    proofConfirm () {
      if (isEmpty(this.proofView.froofImageUrl)) {
        this.$message.error('请先上传营业执照')
        return
      }
      this.proofView.spinning = true
      this.confirmLoading = true
      const obj = {
        froofImageUrl: this.proofView.froofImageUrl,
        froofImageFath: this.proofView.froofImageFath,
      }
      api
        .post('/api/app/enterprise-froof/or-update-enterprise-froof', obj)
        .then(({ data }) => {
          this.$message.success('操作成功')
          getTenantList().then(data => {
            localStorage.setItem('tenantList', JSON.stringify(data))
          })
          this.proofCancel()
          this.proofView.spinning = false
          this.confirmLoading = false
          this.obj.enterpriseProof = 1
          this.obj.enterpriseProofName = '正在认证'
        })
        .catch(err => {
          this.proofView.spinning = false
          this.confirmLoading = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 认证文件上传回调事件
    proofUpload (e) {
      this.proofView.froofImageUrl = e.url
      this.proofView.froofImageFath = e.filePath
    },
    // logo上传回调事件
    imageUpload (e) {
      console.log(e)
      this.obj.enterpriseLogo = e.fileName
      getTenantList().then(data => {
        localStorage.setItem('tenantList', JSON.stringify(data))
      })
      this.getCompanyData()
      // console.log(e)
    },
    // 获取行业列表
    getIndustrySelectList () {
      if (this.industryList.length > 0) {
        return
      }
      api
        .get('/api/app/sys-industry/industry-select-list')
        .then(({ data }) => {
          this.industryList = data
        })
        .catch(err => {
          // this.$message.error('获取行业列表失败')
          console.log(err)
        })
    },

    // 获取企业信息
    getCompanyData () {
      api
        .get('/api/app/sys-enterprise-info/tenants-enterprise')
        .then(({ data }) => {
          this.spinning = false
          this.obj = data
          this.locationAddress = data.location.toString().replace(/,/g, '-')
          this.logoData.companyId = data.id
          this.proofView.data.companyId = data.id
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取企业信息失败')
          console.log(err)
        })
    },

    // 编辑按钮
    editClick (type) {
      switch (type) {
        case 'enterpriseName':
          this.enterpriseName.edit = true
          this.enterpriseName.value = this.obj.enterpriseName
          break
        case 'detailAddress':
          this.detailAddress.edit = true
          this.detailAddress.value = this.obj.detailAddress
          break
        case 'location':
          this.register.edit = true
          this.locationAddress = this.obj.location
          break
        case 'industryName':
          this.getIndustrySelectList()
          this.industryName.edit = true
          this.industryName.value = this.obj.industryId
          break
      }
    },

    // 确认按钮
    affirmClick (type) {
      const obj = this.obj
      switch (type) {
        case 'enterpriseName':
          obj.enterpriseName = this.enterpriseName.value
          if (!obj.enterpriseName) {
            this.$message.error('您还未输入企业名称')
            return
          }
          break
        case 'detailAddress':
          obj.detailAddress = this.detailAddress.value
          if (!obj.detailAddress) {
            this.$message.error('您还未输入详细地址')
            return
          }
          break
        case 'location':
          obj.location = this.locationAddressValue
          if (obj.location) {
            console.log(111)
          }
          break
        case 'industryName':
          obj.industryId = this.industryName.value
          obj.industryName = this.industryList.find(item => item.linkKey === obj.industryId).name
          break
      }
      this.submitUpdateData(obj, type)
    },

    // 取消按钮
    cancelClick (type) {
      switch (type) {
        case 'enterpriseName':
          this.enterpriseName.edit = false
          // console.log(this.enterpriseName.value)
          // console.log(this.obj.enterpriseName)
          this.enterpriseName.value = this.obj.enterpriseName
          // console.log(this.enterpriseName.value)
          // console.log(this.obj.enterpriseName)
          break
        case 'detailAddress':
          this.detailAddress.edit = false
          this.detailAddress.value = this.obj.detailAddress
          break
        case 'location':
          this.register.edit = false
          this.locationAddress = this.obj.location
          break
        case 'industryName':
          this.industryName.edit = false
          break
      }
    },

    // 提交
    submitUpdateData (obj, type) {
      this.spinning = true
      api
        .put('/api/app/sys-enterprise-info/enterprise', obj)
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.cancelClick(type)
          getTenantList().then(data => {
            localStorage.setItem('tenantList', JSON.stringify(data))
          })
          this.getCompanyData()
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          this.cancelClick(type)
          this.getCompanyData()
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.main {
  height: 100%;
  background: #fff;
}

.body {
  margin: 15px;
  .rows {
    width: 80%;
    margin: 10px;
    min-height: 40px;
    line-height: 40px;
    // font-size: 16px;
    .name {
      width: 120px;
      float: right;
    }
    .iconButton {
      cursor: pointer;
      margin-left: 20px;
      .affirm {
        margin-right: 20px;
      }
      .close {
        margin-left: 20px;
      }
    }
    .editOutlined {
      right: 264px;
      top: 22px;
    }
    .isHide {
      position: absolute;
      left: 300px;
      top: 22px;
      color: #b7b7b7;
      cursor: not-allowed;
      // pointer-events: none;
    }
    .value {
      min-width: 100px;
    }
    .tips {
      color: #9999;
      font-size: 12px;
    }
    .input {
      margin: 0px 0 0 25px;
    }
  }
}
.hide {
  color: #b7b7b7;
  cursor: not-allowed;
}
.ant-upload {
  img {
    width: 180px;
    height: 180px;
  }
}
::v-deep(.ant-input) {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
::v-deep(.ant-select) {
  width: 80%;
  height: 40px;
  line-height: 40px;
}
::v-deep(.ant-cascader-picker) {
  width: 80%;
  height: 40px;
  line-height: 40px;
}
::v-deep(.ant-input-lg) {
  font-size: 14px;
}
::v-deep(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  height: 40px;
  line-height: 40px;
}
::v-deep(.ant-select-single
    .ant-select-selector
    .ant-select-selection-item, .ant-select-single
    .ant-select-selector
    .ant-select-selection-placeholder) {
  line-height: 40px;
}
</style>
